import React from 'react';
import * as d3 from 'd3';
import * as jsnx from 'jsnetworkx';
import Switch from "react-switch";
import Export from './Export'

class KnowledgeGraph extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            toggleDisplay: false,
            tripples: this.props.tripplesFiltered
        }
        
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(toggle) {
        this.setState({
            ...this.state,
            toggleDisplay: toggle,
            tripples: toggle ? this.props.tripplesDefault : this.props.tripplesFiltered
        })
    }

    initGraph() {
        var G = new jsnx.MultiDiGraph();
        var tripples = this.state.tripples;
        
        var agents = tripples.map(t => t.agent);
        agents = Array.from(new Set(agents))
        var patients = tripples.map(t => t.patient);
        patients = Array.from(new Set(patients))

        agents.forEach(a => G.addNode(a, { count: 5, textLen: a.length, color: '#3db0fc' }));
        patients.forEach(a => G.addNode(a, { count: 5, textLen: a.length, color: '#7dffcd' }));
        for (let t of tripples) {
            G.addEdgesFrom([[t.agent, t.patient]]);
        }

        return G;
    }

    render() {
        return (
        <div className="row">
            <div className="col-md-12">
                    <h3>Knowledge Graph</h3>
                    <Switch onChange={this.handleToggle} checked={this.state.toggleDisplay} uncheckedIcon={false} checkedIcon={false} onColor="#3db0fc" offColor="#7dffcd" />
                    <span className="switch-label">{this.state.toggleDisplay ? 'Default' : 'Filtered'}</span>
            </div>
            <div id="demo-canvas" className="col-md-12 canvas-container"></div>
            <Export></Export>
        </div>)
    }

    componentDidUpdate() {
        var graph = this.initGraph();
        var hasSrlData = this.state.tripples != null;

        if (hasSrlData) {
            jsnx.draw(graph, {
                element: '#demo-canvas',
                d3: d3,
                width: 1200,
                height: 800,
                withLabels: true,
                withEdgeLabels: true,
                edgeOffset:  35,
                stickyDrag: true,
                nodeShape: 'ellipse',
                edgeLabels: arg => {
                    return this.state.tripples.find(t => t.agent == arg.edge[0] && t.patient == arg.edge[1]).verb_root;
                },
                nodeAttr: {
                    rx: function (d) {
                        return d.data.textLen * 4.5 + 2 * d.data.count;
                    },
                    ry: function (d) {
                        return 12 + d.data.count;
                    }
                },
                layoutAttr: {
                    linkDistance: 450,
                    charge: -200
                },
                nodeStyle: {
                    fill: function (d) {
                        return d.data.color;
                    },
                    'stroke-width': 1,
                    'cursor': 'pointer'
                }
            });
        }

    //     var svg = d3.select("svg"),
    //         width = + svg.attr("width"),
    //         height = + svg.attr("height");

    //     var color = d3.scaleOrdinal(d3.schemeCategory20c);

    //     // Set the dimensions of the nodes
    //     var nd;
    //     for (var i = 0; i < graph.nodes.length; i++) {
    //         nd = graph.nodes[i];
    //         nd.rx = nd.id.length * 4.5 + 2 * nd.group;
    //         nd.ry = 12 + nd.group
    //     }

    //     // Copy of the original data
    //     this.state.baseNodes = graph.nodes;
    //     this.state.baseLinks = graph.links;

    //     this.state.nodes = [...this.state.baseNodes];
    //     this.state.links = [...this.state.baseLinks];
        
    //     // use svg groups to logically group the elements together
    //     this.state.linkGroup = svg.append('g').attr('class', 'link')
    //     this.state.nodeGroup = svg.append('g').attr('class', 'node')
    //     this.state.textGroup = svg.append('g').attr('class', 'labels')


    //     this.state.simulation = d3.forceSimulation()
    //         .force("forceX", d3.forceX().strength(function (d) {
    //             return d.group * .06
    //         })
    //             .x(width * .5))
    //         .force("forceY", d3.forceY().strength(function (d) {
    //             return d.group * .08
    //         })
    //             .y(height * .5))
    //         .force("link", d3.forceLink().id(function (d) { return d.id; }).strength(function (d) { return d.value * .05; }))
    //         .force("collide", this.ellipseForce(6, 0.5, 5))
    //         .force("center", d3.forceCenter(width / 2, height / 2));

    //     this.updateSimulation()
    // }

    // ticked() {
    //     this.state.linkElements
    //         .attr("x1", function(d) { return d.source.x; })
    //         .attr("y1", function(d) { return d.source.y; })
    //         .attr("x2", function(d) { return d.target.x; })
    //         .attr("y2", function(d) { return d.target.y; });
    //     this.state.nodeElements
    //         .attr("cx", function(d) { return d.x; })
    //         .attr("cy", function(d) { return d.y; });
    //     this.state.textElements
    //         .attr("x", function(d) { return d.x; })
    //         .attr("y", function(d) { return d.y; });
    // }

    // onclick(d) {
    //     if (d3.event.defaultPrevented) return;
    //     // console.log(d)

    //     if (this.state.colour_changed_element != undefined){
    //         this.state.colour_changed_element.style("fill", this.state.selected_colour);
    //     }

    //     var element = d3.select(this);
    //     var old_colour = element.style("fill");
    //     element.style("fill", 'black');

    //     //console.log(colour_changed_element.data("id"))
    //     //console.log(element.data("id"))
    //     if (this.state.selected_element_id != d.id) {

    //         //d = d3.select(this).ellipse.data;
    //         // console.log(d)
    //         var neighbors = this.getNeighbors(d)
    //         // console.log(neighbors)
    //         this.state.nodeElements.style("opacity", function (node) {
    //             return (neighbors.indexOf(node.id) > -1) ? 1 : 0.15;
    //         });
    //         this.state.linkElements.style("opacity", function (o) {
    //             return d.id==o.source.id | d.id==o.target.id ? 1 : 0.1;
    //         });
    //         this.state.selected_element_id = d.id;

    //     } else {
    //         element.style("fill", this.state.selected_colour);
    //         this.state.nodeElements.style("opacity", 1);
    //         this.state.linkElements.style("opacity", 1);
    //         // toggle = 0;
    //         this.state.selected_element_id = undefined;
    //     }

    //     this.state.colour_changed_element = element;
    //     this.state.selected_colour = old_colour;
    // }

    // dragstarted(d) {
    //     d.fx = d.x;
    //     d.fy = d.y;
    // }

    // dragged(d) {
    //     if (!this.state.dragging) {
    //         this.state.simulation.alphaTarget(0.3).restart();
    //         this.state.dragging = true;
    //     }
    //     d.fx = d3.event.x;
    //     d.fy = d3.event.y;

    // }

    // dragended(d) {
    //     if (!d3.event.active) 
    //         this.state.simulation.alphaTarget(0);
    //     d.fx = null;
    //     d.fy = null;
    //     this.state.dragging = false;
    // }

    // getNeighbors(node) {
    //     return this.state.baseLinks.reduce(function (neighbors, link) {
    //         if (link.target.id === node.id) {
    //             neighbors.push(link.source.id)
    //         }
    //         /*} else if (link.source.id === node.id) {
    //         neighbors.push(link.target.id)
    //         }*/
    //         return neighbors
    //     },
    //         [node.id]
    //     )
    // }

    // getColour(node) {
    //     //console.log(node.id);
    //     if (node.id == this.state.selectedId) {
    //         console.log(node.id)
    //         return "black"
    //     } else {
    //         return "red";
    //         // return color(node.group)
    //     }
    // }

    // selectNode(selectedNode) {

    //     //simulation.alphaTarget(0.3).restart();
    //     if (this.state.colour_changed_element != undefined) {
    //         this.state.colour_changed_element.style("fill", this.state.selected_colour);
    //     }

    //     var element = d3.select(this);
    //     var old_colour = element.style("fill");
    //     element.style("fill", 'black');

    //     this.state.colour_changed_element = element;
    //     this.state.selected_colour = old_colour;


    //     if (this.state.selectedId === selectedNode.id) {
    //         this.state.selectedId = undefined
    //         this.resetData()
    //         this.updateSimulation()
    //     } else {
    //         this.state.selectedId = selectedNode.id
    //         this.updateData(selectedNode)
    //         this.updateSimulation()
    //     }

    // }

    // resetData() {
    //     var nodeIds = this.state.nodes.map(function (node) { return node.id });
    //     this.state.baseNodes.forEach(function (node) {
    //         if (nodeIds.indexOf(node.id) === -1) {
    //             this.state.nodes.push(node)
    //         }
    //     });
    //     this.state.links = this.state.baseLinks;
    // }

    // updateData(selectedNode) {
    //     var neighbors = this.getNeighbors(selectedNode);
    //     var newNodes = this.state.baseNodes.filter(function (node) {
    //         return neighbors.indexOf(node.id) > -1
    //     });
    //     var diff = {
    //         removed: this.state.nodes.filter(function (node) { return newNodes.indexOf(node) === -1 }),
    //         added: newNodes.filter(function (node) { return this.state.nodes.indexOf(node) === -1 })
    //     };
    //     diff.removed.forEach(function (node) { this.state.nodes.splice(this.state.nodes.indexOf(node), 1) });
    //     diff.added.forEach(function (node) { this.state.nodes.push(node) });
    //     this.state.links = this.state.baseLinks.filter(function (link) {
    //         return link.target.id === selectedNode.id || link.source.id === selectedNode.id
    //     })
    // }

    // updateSimulation() {
    //     this.updateGraph()
    //     this.state.simulation.nodes(this.state.nodes).on("tick", this.ticked);
    //     this.state.simulation.force("link").links(this.state.links);
    //     //simulation.alphaTarget(0.3).restart()
    // }

    // updateGraph() {
    //     // links
    //     this.state.linkElements = this.state.linkGroup
    //         .selectAll("line")
    //         .data(this.state.links, function (link) { return link.target.id + link.source.id });

    //     this.state.linkElements.exit().remove();

    //     var linkEnter = this.state.linkElements
    //         .enter().append("line")
    //         .attr("stroke-width", function (d) { return Math.sqrt(d.value); });

    //     this.state.linkElements = linkEnter.merge(this.state.linkElements)

    //     // Nodes
    //     this.state.nodeElements = this.state.nodeGroup
    //         .selectAll("ellipse")
    //         .data(this.state.nodes, function (node) { return node.id })

    //     this.state.nodeElements.exit().remove();

    //     var nodeEnter = this.state.nodeElements
    //         .enter().append("ellipse")
    //         .attr("rx", function (d) { return d.rx; })
    //         .attr("ry", function (d) { return d.ry; })
    //         //.attr("fill", function(d) { return color(d.group); })
    //         .attr("fill", function (d) { return this.getColour(d); })
    //         .call(d3.drag()
    //             .on("start", this.dragstarted)
    //             .on("drag", this.dragged)
    //             .on("end", this.dragended))
    //         .on('dblclick', this.selectNode)
    //         .on("click", this.onclick);

    //     this.state.nodeElements = nodeEnter.merge(this.state.nodeElements);

    //     // texts
    //     this.state.textElements = this.state.textGroup
    //         .selectAll("text")
    //         .data(this.state.nodes, function (node) { return node.id })

    //     this.state.textElements.exit().remove()

    //     var textEnter = this.state.textElements
    //         .enter().append("text")
    //         .attr("dy", 2)
    //         .attr("text-anchor", "middle")
    //         .text(function (d) { return d.id })
    //         .attr("fill", "white")
    //         .style("font-size", function (d) {
    //             return 10 + d.group * .5
    //         })

    //     this.state.textElements = textEnter.merge(this.state.textElements)

    //     this.state.simulation.alphaTarget(0.3).restart()

    //     var t = d3.timer(
    //         function (elapsed) {
    //             if (elapsed > 200) {
    //                 t.stop();
    //                 this.state.simulation.alphaTarget(0);
    //             }
    //         }, 200);

    // }

    // constant(x) {
    //     return function () {
    //         return x;
    //     };
    // }

    // ellipseForce (padding, innerRepulsion, outerRepulsion) {
    //     var nodes;

    //     if (typeof padding !== "function") padding = this.constant(padding == null ? 4 : +padding);
    //     innerRepulsion = innerRepulsion == null ? 0.5 : +innerRepulsion;
    //     outerRepulsion = outerRepulsion == null ? 0.5 : +outerRepulsion;

    //     function force(alpha) {
    //         var i, j, n = nodes.length,
    //             // dimensions of this node
    //             node, my_padding, my_w, my_h, my_x, my_y,
    //             // often used multiples
    //             my_w2, my_h2, my_wh,
    //             // dimensions of the other node
    //             other, other_padding, other_w, other_h, other_x, other_y,
    //             // distance between nodes
    //             dist_x, dist_y,
    //             // components for the overall result
    //             force_ratio, dist, gap, repulsion, x_component, y_component,
    //             // computing elliptical force
    //             g, g2, x1, y1, x2, y2, d1, d2,
    //             force_ratio1, force_ratio2,
    //             // parameters
    //             myOuterRepulsion = outerRepulsion * 16;

    //         for (i = 0; i < n; ++i) {
    //             node = nodes[i];
    //             my_padding = +padding(node, i, nodes);
    //             my_w = node.rx + my_padding;
    //             my_h = node.ry + my_padding;
    //             my_w2 = my_w * my_w;
    //             my_h2 = my_h * my_h;
    //             my_wh = my_w * my_h;
    //             my_x = node.x + node.vx;
    //             my_y = node.y + node.vy;

    //             for (j = 0; j < n; ++j) {
    //                 if (j == i) {
    //                     continue;
    //                 }
    //                 other = nodes[j];
    //                 other_padding = +padding(other, j, nodes);
    //                 other_w = other.rx + other_padding;
    //                 other_h = other.ry + other_padding;
    //                 other_x = other.x + other.vx;
    //                 other_y = other.y + other.vy;
    //                 dist_x = my_x - other_x;
    //                 dist_y = my_y - other_y;
    //                 if (dist_x == 0 && dist_y == 0) {
    //                     node.vx += (Math.random() * 4) - 2;
    //                     node.vy += (Math.random() * 4) - 2;
    //                     continue;
    //                 } else if (dist_x == 0) {
    //                     force_ratio = (my_h / my_w + other_h / other_w) / 2;
    //                     dist = Math.abs(dist_y);
    //                     gap = dist - my_h - other_h;
    //                 } else if (dist_y == 0) {
    //                     force_ratio = 1;
    //                     dist = Math.abs(dist_x);
    //                     gap = dist - my_w - other_w;
    //                 } else {
    //                     // ellipse is defined as  x^2   y^2
    //                     //                        --- + --- = 1
    //                     //                        w^2   h^2
    //                     // here x,y are points on ellipse's arc.
    //                     // we have a line going between center points of two ellipses and we want to know
    //                     // the point where it crosses the ellipse's arc. Because we know the line, we
    //                     // know that y = g * x, where
    //                     g = dist_y / dist_x;
    //                     // now the only unknown in ellipse above is x, and thus we can find it by
    //                     // moving pieces around (pen and paper work). equation becomes:
    //                     //             w * h
    //                     // x = ---------------------
    //                     //     sqrt(h^2 + g^2 * w^2)

    //                     g2 = g * g;
    //                     x1 = my_wh / Math.sqrt(my_h2 + g2 * my_w2);
    //                     y1 = g * x1;
    //                     // the length of the little bit from the center of ellipse to its margin.
    //                     // For circle it would be 'r', but for ellipse it varies.
    //                     d1 = Math.sqrt(x1 * x1 + y1 * y1);
    //                     // Strength of force that this ellipse eminates is modified by ratio of this bit
    //                     // to the ellipse's width. (It doesn't matter if we use width or height as reference
    //                     // point)
    //                     force_ratio1 = d1 / my_w;
    //                     // And same for the other ellipse:
    //                     x2 = (other_w * other_h) / Math.sqrt(other_h * other_h + g2 * other_w * other_w);
    //                     y2 = g * x2;
    //                     d2 = Math.sqrt(x2 * x2 + y2 * y2);
    //                     force_ratio2 = d2 / other_w;
    //                     // now we can calculate the gap or overlap between two ellipses, and force ratio on
    //                     // how strongly they should push as average of their force_ratios
    //                     dist = Math.sqrt(dist_x * dist_x + dist_y * dist_y);
    //                     gap = dist - d2 - d1;
    //                     force_ratio = (force_ratio1 + force_ratio2) / 2;
    //                 }
    //                 x_component = dist_x / dist;
    //                 y_component = dist_y / dist;
    //                 if (gap < 0) { // force GROWS as gap goes further into negative
    //                     repulsion = Math.min(Math.max(1.0, innerRepulsion * force_ratio * -gap), 5.0);
    //                     node.vx += repulsion * x_component;
    //                     node.vy += repulsion * y_component;
    //                 } else { // force DIMINISHES as gap becomes larger
    //                     repulsion = Math.min(20.0, (force_ratio * myOuterRepulsion * alpha) / gap);
    //                     node.vx += repulsion * x_component;
    //                     node.vy += repulsion * y_component;
    //                 }
    //             }
    //         }
    //     }

    //     force.initialize = function (my_nodes) {
    //         nodes = my_nodes;
    //     };

    //     force.outerRepulsion = function (my_outerRepulsion) {
    //         if (arguments.length) {
    //             outerRepulsion = +my_outerRepulsion;
    //             return force;
    //         } else {
    //             return outerRepulsion;
    //         }
    //     };

    //     force.innerRepulsion = function (my_innerRepulsion) {
    //         if (arguments.length) {
    //             innerRepulsion = +my_innerRepulsion;
    //             return force;
    //         } else {
    //             return innerRepulsion;
    //         }
    //     };

    //     force.padding = function (my_padding) {
    //         if (arguments.length) {
    //             if (typeof my_padding === "function") {
    //                 padding = my_padding;
    //             } else {
    //                 padding = this.constant(+my_padding);
    //             }
    //             return force;
    //         } else {
    //             return padding;
    //         }
    //     };


    //     return force;
    };

}

export default KnowledgeGraph