import React from 'react';
import { connect } from "react-redux";

import { performNerAPI } from './store/actions/index'
import TextClassifyer from './components/TextClassifyer'
import TextClass from './components/TextClass'
import Ner from './components/Ner'
import Srl from './components/Srl'
import Export from './components/Export';

import './App.css';

class App extends React.Component {

  constructor(props) {
    super(props)

    this.detailedAnalyticsHandler = this.detailedAnalyticsHandler.bind(this)
  }

  detailedAnalyticsHandler() {
    if(this.props.store.textToBeAnalyzed) {
      this.props.performNerAPI(this.props.store.textToBeAnalyzed)
    }
  }

  render() {
    return (
      <div className="container container-fluid">

        <div className="row vertical-whitespace"></div>

        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center">Pharma Text Analytics Platform</h3>
          </div>
        </div>

        <TextClassifyer></TextClassifyer>

        <TextClass detailedAnalyticsHandler={this.detailedAnalyticsHandler}></TextClass>

        <Ner></Ner>

        <hr />

        <Srl></Srl>

      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    store: state
  }
}


export default connect(mapStateToProps, { performNerAPI })(App);
