import React from 'react';
import { connect } from "react-redux";

class Export extends React.Component {

    constructor(props) {
        super(props)

        this.download = this.download.bind(this)
    }

    download() {
        var a = document.createElement("a");
        var file = new Blob([JSON.stringify(this.props.srlData)], { type: "application/json" });
        a.href = URL.createObjectURL(file);
        a.download = "export_srl.json";
        a.click();
    }

    render() {
        var hasSrlData = this.props.srlData != null;

        return (hasSrlData && <button className="btn btn-success" onClick={this.download}>Export Data</button>)
    }
}

const mapStateToProps = state => {
    return {
        srlData: state.srlData
    }
}

export default connect(mapStateToProps)(Export)