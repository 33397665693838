import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";

class RDF_Knowledge_Graph extends React.Component {

    DBPEDIA_SPOTLIGHT_API = "http://api.dbpedia-spotlight.org/en/annotate";
    W3C_SDE = "https://www.w3.org/2012/sde/extract";

    DBPEDIA_RES_RESOURCE_URI = "http://dbpedia.org/resource/";
    DBPEDIA_RES_TYPE_PREFIX = "DBpedia:"
    SCHEMA_RESOURCE_URI = "http://schema.org";
    SCHEMA_TYPE_PREFIX = "Schema:"

    CUSTOM_RESOURCE_URI = "http://www.ph-analytics.org/resource/"
    
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.loadData();
    }

    download(data) {
        var a = document.createElement("a");
        var file = new Blob([data], { type: "text/turtle" });
        a.href = URL.createObjectURL(file);
        a.download = "export_srl.ttl";
        a.click();
    }

    async loadData() {
        
        var text = this.props.text.map(t => t.sentence).join(" ");
        var resources = [];

        var turtle_final = "@prefix pharma_an: <http://www.ph-analytics.org/resource/> .\n@prefix dbpedia: <http://dbpedia.org/resource/> .\n@prefix dbpo: <http://dbpedia.org/ontology/> .\n@prefix schema: <http://schema.org/> .\n\n\n";

        var res0 = await axios
        .get(this.DBPEDIA_SPOTLIGHT_API + "?text=" + encodeURIComponent(text), {
            headers: {
                "Accept": "application/json"
            }
        })
        .then(a => a.data)

        // res0['Resources'][i]['surfaceForm'] : res0['Resources'][i]['URI']
        // eg: Sanofi: http://dbpedia.org/resource/Sanofi
        var entity_resource_map = new Map();

        for (var resource of res0['Resources']) {
            entity_resource_map.set(resource['@surfaceForm'], resource['@URI']);

            var types = resource['@types'].split(",").filter(t => t.indexOf(this.DBPEDIA_RES_TYPE_PREFIX) != -1 || t.indexOf(this.SCHEMA_TYPE_PREFIX) != -1);

            for(var t of types) {
                var resource_with_prefix = "";
                if(t.indexOf(this.DBPEDIA_RES_TYPE_PREFIX) != -1) {
                    resource_with_prefix += ("dbpedia:" + resource['@URI'].substr(28) + " a " + "dbpo:" + t.substr(8) + " .\n")
                    resources.push(resource['@URI'].substr(28));
                }
                if(t.indexOf(this.SCHEMA_TYPE_PREFIX) != -1) {
                    resource_with_prefix += ("dbpedia:" + resource['@URI'].substr(28) + " a " + "schema:" + t.substr(7) + " .\n")
                    resources.push(resource['@URI'].substr(28));
                }
                
                if(resource_with_prefix.length > 0) {
                    turtle_final += resource_with_prefix;
                }

            }
        }

        turtle_final += "\n";
        
        for(let e of this.props.nerData.entities.custom_entities) {
            if (e.type == "Pharma_org") {
                turtle_final += ("dbpedia:" + e.entity + " a " + "schema:MedicalOrganization .\n")
            }
            else {
                turtle_final += ("dbpedia:" + e.entity + " a " + "schema:Drug .\n")
                turtle_final += ("dbpedia:" + e.entity + " a " + "dbpo:Drug .\n")
            }
        }

        
        this.download(turtle_final);
    }

    get_object(object_literal, entity_resource_map) {
        var object_literal_ret = " \"" + object_literal + "\"";

        entity_resource_map.forEach((v, k) => {
            if (object_literal_ret.toLowerCase().indexOf(k.toLowerCase()) != -1) {
                object_literal_ret = " dbpedia:" + v.substr(18);
                return;
            }
        });

        return object_literal_ret;
    }

    render() {
        return (<div></div>)
    }

}

export default RDF_Knowledge_Graph