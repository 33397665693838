import { ANALYZE_TEXT, CLASSIFY_TEXT, LOADING, PERFORM_NER, PERFORM_COREF, PERFORM_SRL } from "../constants/action-types";

const initialState = {
    loading: false,
    textToBeAnalyzed: null,
    classification: null,
    nerData: null,
    corefData: null,
    srlData: null
};

function rootReducer(state = initialState, action) {
    if(action.type == LOADING) {
        return {
            ...state,
            loading: action.payload
        }
    }
    else if(action.type == ANALYZE_TEXT) {
        return {
            ...initialState,
            textToBeAnalyzed: action.payload
        }
    }
    else if(action.type == CLASSIFY_TEXT) {
        return {
            loading: state.loading,
            textToBeAnalyzed: state.textToBeAnalyzed,
            classification: action.payload.predicted_class,
            nerData: null,
            corefData: null,
            srlData: null
        }
    }
    else if(action.type == PERFORM_NER) {
        return {
            ...state,
            nerData: action.payload
        }
    }
    else if(action.type == PERFORM_COREF) {
        return {
            ...state,
            corefData: action.payload
        }
    }
    else if(action.type == PERFORM_SRL) {
        return {
            ...state,
            srlData: action.payload
        }
    }
    return state;
};

export default rootReducer;