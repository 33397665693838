import React from 'react';
import { connect } from "react-redux";
import { performSrlAPI } from '../store/actions/index';
import SrlVerbsTable from './SrlVerbsTable';
import Select from 'react-select';
import KnowledgeGraph from './KnowledgeGraph';
import RDF_Knowledge_Graph from './RDF_Knowledge_Graph';

class Srl extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedSentence: null
        }

        this.handleSelectSentence = this.handleSelectSentence.bind(this);
    }

    triggerSrlAnalysis() {
        this.props.performSrlAPI(this.props.corefData.sentence, this.props.nerData.entities.drugs.concat(this.props.nerData.entities.ph_orgs))
    }

    handleSelectSentence = selectedSentence => {
        this.setState({ ...this.state, selectedSentence: selectedSentence });
    };

    render() {
        var hasSrlData = this.props.srlData != null;
        var hasCorefData = this.props.corefData != null;

        var availableSentences = []
        var selectedSentence = null;

        if(hasCorefData && !hasSrlData) {
            selectedSentence = null;
            this.triggerSrlAnalysis()
        }
        else {
            selectedSentence = this.state.selectedSentence;
        }

        if(hasSrlData) {
            for(var sentence of this.props.srlData.sentences_srl){
                availableSentences.push({
                    value: sentence.index,
                    label: sentence.sentence.substring(0, 100) + "..."
                })
            }
        }

        return (hasSrlData && 
        <div className="row space-top space-bottom srl-container">
            <div className="col-md-12">
                <h3>Semantic Role Labeling</h3>

                <Select
                    value={selectedSentence}
                    onChange={this.handleSelectSentence}
                    options={availableSentences}/>
            </div>

            <RDF_Knowledge_Graph text={this.props.srlData.sentences_srl} nerData={this.props.nerData}></RDF_Knowledge_Graph>

            {selectedSentence && (<div className="col-md-12">
                <br />
                <h5>{this.props.srlData.sentences_srl[selectedSentence.value].sentence}</h5>
                <br />

                <SrlVerbsTable verbs={this.props.srlData.sentences_srl[selectedSentence.value].verbs}></SrlVerbsTable>
                <KnowledgeGraph tripplesDefault={this.props.srlData.tripples_default} tripplesFiltered={this.props.srlData.tripples_filtered} nerData={this.props.nerData}></KnowledgeGraph>

            </div>)}
        </div>)
    }

}

const mapStateToProps = state => {
    return {
        nerData: state.nerData,
        corefData: state.corefData,
        srlData: state.srlData
    }
}

export default connect(mapStateToProps, { performSrlAPI })(Srl)