import React from 'react';
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

class TextClass extends React.Component {

    constructor(props){
        super(props)
    }

    render() {
        var isPharmaOrg = this.props.classification && this.props.classification == "Pharma"
        return (this.props.classification && 
        <div className="row space-top">
            <div className="col-md-12">
                <div className={"alert " + (isPharmaOrg ? 'alert-success' : 'alert-danger')} role="alert">
                    <div className="row">
                        <div className="col-md-12">
                            {isPharmaOrg ? 'Pharmaceutical Text  ' : 'Not a pharmaceutical text  '}

                            {isPharmaOrg && (
                                <button type="button" className="btn btn-light float-right" onClick={this.props.detailedAnalyticsHandler}>
                                    Detailed Analytics <FontAwesomeIcon icon={faChevronDown} />
                                </button>)}
                        </div>
                    </div>

                </div>
            </div>
        </div>)
    }

}

const mapStateToProps = state => {
    return {
        classification: state.classification
    }
}

export default connect(mapStateToProps)(TextClass)