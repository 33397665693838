import React from 'react';
import { connect } from "react-redux";
import { performCorefAPI } from '../store/actions/index';

class Ner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            toggleEntities: false,
            colors: {
                'Drug': '#3db0fc', 
                'Pharma_org': '#7dffcd',
                'Chemical': '#faf450',
                'Disease': '#fa509f',
                'Amino_acid': '#b56bff',
                'Anatomical_system': '#96cdfa',
                'Cancer': '#ff6b9c',
                'Cell': '#997f88',
                'Cellular_component': '#d3db84',
                'Developing_anatomical_structure': '#d4af6c',
                'Gene_or_gene_product': '#fd6bff',
                'Immaterial_anatomical_entity': '#6bffc6',
                'Multi-tissue_structure': '#ffa196',
                'Organ': '#f2a23f',
                'Organism': '#ff30d9',
                'Organism_subdivision': '#a940ff',
                'Organism_substance': '#39ad8b',
                'Pathological_formation': '#ff1c24',
                'Simple_chemical': '#7fdbf5'
            }
        }

        this.handleToggle = this.handleToggle.bind(this)
    }

    handleToggle(toggle) {
        this.setState({
            ...this.state,
            toggleEntities: toggle
        })
    }

    triggerCorefAnalysis() {
        this.props.performCorefAPI(this.props.textToBeAnalyzed, this.props.nerData.entities.drugs.concat(this.props.nerData.entities.ph_orgs))
    }

    componentDidMount() {}

    render() {
        var hasNerData = this.props.nerData != null;
        var hasCorefData = this.props.corefData != null;

        var drugEntities = ''
        var pharmaEntities = ''
        var total = []

        if(hasNerData) {
            
            var sentence = this.props.nerData.tokens.join(" ");
            
            var entities_and_positions = []

            for (var e of this.props.nerData.entities.all_entities) {
                var re = new RegExp(e.entity, 'g');
                var results = new Array();
                while (re.exec(sentence)) {
                    results.push(re.lastIndex - e.entity.length);
                }

                for(var r of results) {
                    entities_and_positions.push({
                        entity: e,
                        start: r,
                        end: r + e.entity.length
                    })
                }
            }

            entities_and_positions.sort((a, b) => {
                if (a.start > b.start)
                    return 1;
                else if (a.start < b.start)
                    return -1
                return 0
            })

            var currentEntityIndex = 0;
            for(let i = 0; i < sentence.length; i++) {
                if (currentEntityIndex > entities_and_positions.length - 1) {
                    total.push(<span className="ner-char">{sentence[i]}</span>);
                    continue;
                }
                if (i < entities_and_positions[currentEntityIndex].start) {
                    total.push(<span className="ner-char">{sentence[i]}</span>);
                }
                else if (i >= entities_and_positions[currentEntityIndex].start) {
                    total.push(<span className="entity" style={{ 'background-color': this.getColorForEntity(entities_and_positions[currentEntityIndex].entity.type) }}>
                    {entities_and_positions[currentEntityIndex].entity.entity}
                    <span className="tag">{entities_and_positions[currentEntityIndex].entity.type}</span>
                    </span>)
                    i += entities_and_positions[currentEntityIndex].entity.entity.length;
                    currentEntityIndex++;
                }
            }


            drugEntities = (
                <div>
                    {this.props.nerData.entities.drugs.length > 0 ? this.props.nerData.entities.drugs.map(a => <span>{a}, </span>) : 'None'}
                </div>
            )

            pharmaEntities = (
                <div>
                    {this.props.nerData.entities.ph_orgs.length > 0 ? this.props.nerData.entities.ph_orgs.map(a => <span>{a}, </span>) : 'None'}
                </div>
            )
            
        }
        if(hasNerData && !hasCorefData) {
            this.triggerCorefAnalysis()
        }

        return ( hasNerData && 
            <div className="row space-top space-bottom">
                <div className="col-md-12 entities-list">
                    <h3>Recognized Entities</h3>
                    <b>Drugs:</b> {drugEntities}
                    <b>Pharma Organizations:</b> {pharmaEntities}
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="entities-sentences">

                            <div className="col-md-12">
                                {total}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getTaggedSentenceFull(bio_tags, tokens){
        var sentenceTags = []
        var span = []
        bio_tags.forEach((value, index) => {
            if (value[0] == "O" && span.length == 0) {
                sentenceTags.push(<span className="ne-tag">{tokens[index]} </span>)
            }
            else if (value[0] == "O" && span.length > 0) {
                var entity = span.join(' ')
                sentenceTags.push(<span className="ne-tag entity" style={{ 'background-color': this.getColorForEntity(bio_tags[index - 1].substring(2))}}>{entity} <span className="tag">{bio_tags[index - 1].substring(2)}</span></span>)
                sentenceTags.push(<span className="ne-tag">{tokens[index]} </span>)
                span = []
            }
            else if (value[0] == "B" || value[0] == "I") {
                span.push(tokens[index])
            }
        })

        return sentenceTags
    }

    getColorForEntity(entity) {
        return this.state.colors[entity]
    }
}

const mapStateToProps = state => {
    return {
        textToBeAnalyzed: state.textToBeAnalyzed,
        nerData: state.nerData,
        corefData: state.corefData
    }
}

export default connect(mapStateToProps, { performCorefAPI })(Ner)