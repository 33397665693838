import React from 'react';
import { connect } from "react-redux";
import { classifyTextAPI } from '../store/actions/index'
import Spinner from '../components/Spinner'

class TextClassifyer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            textValue: ""
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        this.setState({ textValue: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.classifyTextAPI(this.state.textValue)
    }

    render() {
        return (<div className="row">
            <div className="col-md-12">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <textarea className="form-control" rows="6" placeholder="Insert text here" value={this.state.textValue} onChange={this.handleChange}></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary float-right">
                        Analyze
                    </button>
                    <Spinner></Spinner>

                </form>
            </div>
        </div>)
    }

}



export default connect(null, { classifyTextAPI })(TextClassifyer)