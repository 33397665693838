import React from 'react';
import { connect } from "react-redux";

class Spinner extends React.Component {

    render() {
        var isLoading = this.props && this.props.loading
        return (isLoading &&<div className="spinner-border text-primary text-center" role="status">
            <span className="sr-only">Loading...</span>
        </div>)
    }

}

const mapStateToProps = state => {
    return {
        loading: state.loading
    }
}


export default connect(mapStateToProps)(Spinner)