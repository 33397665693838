import React from 'react';
import SrlArg from './SrlArg';

class SrlVerbsTable extends React.Component {

    AGENT = "Agent"
    PATIENT = "Patient"

    constructor(props) {
        super(props)
    }

    render() {        
        var agentFilter = e => e.argDescription == this.AGENT;
        var patientFilter = e => e.argDescription == this.PATIENT;

        var verbMap = this.props.verbs.map(v => {
            var verb =  v.verb
            var desc =  v.description

            var args = this.extractArgs(desc)

            var agent = args.find(agentFilter)
            var patient = args.find(patientFilter)

            var args = args.filter(a => !agentFilter(a) && !patientFilter(a)).map(a => <SrlArg data={a.data} arg={a.argDescription} /> );

            return (<tr>
                <td>{agent ? <SrlArg data={agent.data} arg={agent.argDescription} />  : '-' }</td>
                <td>{ <SrlArg data={verb} arg="Verb" /> }</td>
                <td>{patient ? <SrlArg data={patient.data} arg={patient.argDescription} />  : '-'}</td>
                <td>
                    <div className="args-float-container">
                        <div className="args-float-inner">{args}</div>
                    </div>
                </td>
            </tr>)
        })

        return (
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Verb</th>
                        <th>Patient</th>
                        <th>Arguments</th>
                    </tr>
                </thead>
                {verbMap}
            </table>
        )
    }

    extractArgs(desc) {
        var args = []

        var args_from_string = desc.match(/\[ARG[^\]]*\]/g)

        var order = 0
        for (let a of args_from_string) {
            var cleaned_arg = a.replace(/^\[|\]$/g, '');
            var index_to_split = cleaned_arg.indexOf(":");
            var argStr = cleaned_arg.substring(0, index_to_split);
            var argData = cleaned_arg.substring(index_to_split + 2);

            args.push({
                order: order,
                argDescription: this.getDescriptionForArg(argStr),
                data: argData
            })
            order++
        }

        return args
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getDescriptionForArg(arg) {
        if (arg == "ARG0")
            return this.AGENT
        else if (arg == "ARG1")
            return this.PATIENT
        else if (arg == "ARG2")
            return "Instrument"
        else if (arg == "ARG3")
            return "Attribute"
        else if (arg == "ARG4")
            return "End Point"
        else if (arg == "ARGM-PRP")
            return "Purpose"
        else if (arg == "ARGM-MNR")
            return "Manner"
        else if (arg == "ARGM-LOC")
            return "Location"
        else if (arg == "ARGM-TMP")
            return "Temporal"
        else if (arg == "ARGM-MOD")
            return "Modal"
        else if (arg == "ARGM-ADV")
            return "Adverbial"
        else if (arg == "ARGM-DIS")
            return "Discourse"
        else return "Unknown"
    }
}

export default SrlVerbsTable