import { ANALYZE_TEXT, CLASSIFY_TEXT, LOADING, PERFORM_NER, PERFORM_COREF, PERFORM_SRL } from '../constants/action-types'

const API_URL = "http://pharmke.env4health.finki.ukim.mk/api/"

export function analyzeText(payload) {
    return { type: ANALYZE_TEXT, payload }
};

export function classifyText(payload) {
    return { type: CLASSIFY_TEXT, payload }
};

export function performNer(payload) {
  return { type: PERFORM_NER, payload }
}

export function loading(payload) {
  return { type: LOADING, payload }
};

export function classifyTextAPI(text) {
  return function(dispatch) {
    dispatch({ type: ANALYZE_TEXT, payload: text });
    dispatch({ type: LOADING, payload: true });
    return fetch(API_URL + "classify-text", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
          'text': text
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: CLASSIFY_TEXT, payload: json });
        dispatch({ type: LOADING, payload: false });
      })
      .catch(err => {
        dispatch({ type: LOADING, payload: false });
        console.log(err)
      });
  };
}


export function performNerAPI(text) {
  return function (dispatch) {
    dispatch({ type: LOADING, payload: true });
    return fetch(API_URL + "ner", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        'text': text
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: PERFORM_NER, payload: json });
        dispatch({ type: LOADING, payload: false });
      })
      .catch(err => {
        dispatch({ type: LOADING, payload: false });
        console.log(err)
      });
  };
}

export function performCorefAPI(text, entities) {
  return function (dispatch) {
    dispatch({ type: LOADING, payload: true });
    return fetch(API_URL + "coreference", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        'text': text,
        'entities': entities
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: PERFORM_COREF, payload: json });
        dispatch({ type: LOADING, payload: false });
      })
      .catch(err => {
        dispatch({ type: LOADING, payload: false });
        console.log(err)
      });
  };
}

export function performSrlAPI(text, entities) {
  return function (dispatch) {
    dispatch({ type: LOADING, payload: true });
    return fetch(API_URL + "srl", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        'text': text,
        'entities': entities
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: PERFORM_SRL, payload: json });
        dispatch({ type: LOADING, payload: false });
      })
      .catch(err => {
        dispatch({ type: LOADING, payload: false });
        console.log(err)
      });
  };
}
