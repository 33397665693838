import React from 'react';

class SrlArg extends React.Component {
    
    constructor(props) {
        super(props)
    }

    render() {
        var data = this.props.data
        var arg = this.props.arg
        
        var isVerb = arg === "Verb";

        return (
            <div className="srl-data-container">
                <div className="srl-arg-container">
                    <span className="srl-arg-data">{data}</span>
                    <span className={"srl-arg-arg " + (isVerb ? 'arg-verb' : '')} >{arg}</span>
                </div>
            </div>
        )
    }

}

export default SrlArg